<template>
  <div class="ma-2">
    <v-card-title> Backup/Restore Account </v-card-title>
    <div class="text-center mt-5">
      <v-row>
        <v-col>
          <span
            >To backup your account, simply export the data and save it.</span
          ><br />
          <v-btn large color="info" @click="processExport()"
            >Export Data <v-icon>mdi-export</v-icon></v-btn
          >
        </v-col>
        <v-col>
          <span
            >To restore your account, simply import the data you saved
            previously.</span
          ><br />
          <v-btn large @click="$refs.file.click()" color="cyan" dark>
            <v-icon>mdi-import</v-icon> Import Data
          </v-btn>
          <input
            type="file"
            class="d-none"
            ref="file"
            @change="handleFile($event.target.files[0])"
          />
          <div v-if="backupFile" class="text-center mt-3">
            <v-chip color="primary" rounded
              ><v-icon>mdi-file-outline</v-icon> {{ backupFile.name }}</v-chip
            >
            <br />
            <v-btn color="info" class="mt-4" rounded @click="processImport"
              >Submit</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-slide-y-transition>
        <div v-if="log[0]">
          <v-divider class="mt-3 mb-3"></v-divider>
          <div class="text-right">
            <v-btn icon @click="clearLog()"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <v-row>
            <v-col v-if="!exporting" cols="12" sm="6"></v-col>
            <v-col cols="12" sm="6">
              <div class="text-left log-container" v-if="log[0]">
                <h2>
                  Status:
                  <v-chip
                    small
                    :color="backupStatus == 'Completed' ? 'success' : 'info'"
                    v-if="backupStatus"
                    >{{ backupStatus }}</v-chip
                  >
                </h2>
                <div class="mt-1" v-for="(l, index) in log" :key="index">
                  {{ l }}
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-slide-y-transition>
      <div>
        <SolarDataImport />
      </div>
    </div>
  </div>
</template>

<script>
import backup from '@/mixins/backup'
export default {
  mixins: [backup],
  components: {
    SolarDataImport: () => import('./SolarDataImport.vue')
  },
  data: () => ({
    dialogs: {
      backup: false
    },
    backupFile: null,
    exporting: false
  }),
  methods: {
    handleFile(file) {
      this.backupFile = file
    },
    async processImport() {
      this.exporting = false
      await this.importData(this.backupFile)
      this.backupFile = null
    },
    processExport() {
      this.exporting = true
      this.exportData()
    }
  }
}
</script>

<style scoped>
.log-container {
  height: 250px;
  overflow-y: scroll;
}
</style>
