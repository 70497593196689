import { userRef, getRef, parcelRef } from '@/firebase/db'
import user from '@/mixins/user'
export default {
  mixins: [user],
  data: () => ({
    export: {
      userProfile: {},
      parcels: [],
      tax: [],
      water: [],
      septic: [],
      hvac: [],
      solar: []
    },
    featuresToExport: [],
    exportUser: null,
    log: [],
    backupStatus: null
  }),
  methods: {
    clearLog() {
      this.log = []
    },
    downloadJSON(exportObj, exportName) {
      var dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(exportObj))
      var downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute('download', exportName + '.json')
      document.body.appendChild(downloadAnchorNode)
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },
    async exportUserData() {
      this.log.push('Exporting Profile data')
      let userProfileData = await userRef.doc(this.exportUser.uid).get()
      if (userProfileData.exists) {
        this.export.userProfile = { ...userProfileData.data() }
        this.log.push(`Profile: ${userProfileData.data().email}`)
      }
    },
    async exportParcels() {
      this.log.push('Exporting Parcels')
      let parcels = await parcelRef
        .where('user', '==', this.exportUser.uid)
        .get()
      parcels.forEach(async p => {
        this.export.parcels.push({ ...p.data(), id: p.id })
        this.featuresToExport.push(p.id)
        this.log.push(`Parcel: ${p.id}`)
      })
    },

    async exportFeature(id, feature) {
      let ref = getRef(feature)
      if (!ref) {
        return
      }
      let data = await ref.doc(id).get()
      if (data.exists) {
        data = { ...data.data(), id: data.id }
        this.log.push(`${feature}: ${id}`)
        this.export[feature].push(data)
      }
    },
    async exportFeatures() {
      this.log.push('Exporting features')
      for (let id of this.featuresToExport) {
        await this.exportFeature(id, 'tax')
        await this.exportFeature(id, 'water')
        await this.exportFeature(id, 'septic')
        await this.exportFeature(id, 'hvac')
        await this.exportFeature(id, 'solar')
      }
    },
    async exportData() {
      this.log = []
      this.backupStatus = 'Processing'
      this.exportUser = await this.currentUser()
      await this.exportUserData()
      await this.exportParcels()
      await this.exportFeatures()
      this.log.push('Downloading JSON')
      this.downloadJSON(
        this.export,
        `${this.$store.getters.user.profile.first_name} ${this.$store.getters.user.profile.last_name}`
      )
      this.backupStatus = 'Completed'
    },
    async importData(file) {
      let reader = new FileReader()
      reader.onload = e => {
        this.importFromJSON(JSON.parse(e.target.result))
      }
      reader.readAsText(file)
    },
    async importUserProfile(userProfile) {
      userProfile.email = this.exportUser.email
      this.log.push('Importing user profile')
      await userRef.doc(this.currentUser().uid).set(userProfile)
    },
    async importParcels(data) {
      this.log.push('Importing parcels')
      for (let i of data) {
        this.log.push(`Parcel: ${i.id}`)
        let parcel = { ...i }
        parcel.user = this.exportUser.uid
        let parcelID = parcel.id
        delete parcel.id
        await parcelRef.doc(parcelID).set(parcel)
      }
    },
    async importFeature({ data, feature }) {
      let ref = getRef(feature)
      for (let i of data) {
        this.log.push(`${feature}: ${i.id}`)
        let ID = i.id
        delete i.id
        await ref.doc(ID).set(i)
      }
    },
    async importFromJSON(data) {
      this.exportUser = await this.currentUser()
      this.backupStatus = 'Processing'
      this.log = []
      this.log.push('Importing data from file')
      await this.importUserProfile(data.userProfile)
      await this.importParcels(data.parcels)
      await this.importFeature({ feature: 'tax', data: data.tax })
      await this.importFeature({ feature: 'water', data: data.water })
      await this.importFeature({ feature: 'septic', data: data.septic })
      await this.importFeature({ feature: 'hvac', data: data.hvac })
      await this.importFeature({ feature: 'solar', data: data.solar })

      this.log.push('Completed')
      this.backupStatus = 'Completed'
      this.updateMetrics()
    }
  }
}
